<template>
  <section id="results">

    <b-card no-body>
      <!-- <h4 class="mt-2 mr-2 ml-2">Filter</h4> -->
      <b-row class="mt-1 mr-1 mb-1 ml-1">
         <!-- <b-col md="12">
          <h4>Filter</h4>
         </b-col> -->
        
        <b-col md="3">
          <b-form-group
            label="Urgency"
            label-for="inputGender"
            label-cols-md="12"
          >
            <v-select
              id="inputUrgency"
              v-model="selectedUrgencyLevel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="urgencyLevels"
              placeholder="Filter by urgency"
              @select="loadResults"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Advice"
            label-for="inputAdvice"
            label-cols-md="12"
          >
            <v-select
              id="inputAdvice"
              v-model="selectedAdvice"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="advices"
              placeholder="Filter by advice"
              @change="loadResults"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Exercises"
            label-for="inputExercises"
            label-cols-md="12"
          >
            <v-select
              id="inputExercises"
              v-model="selectedExercise"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="exercises"
              placeholder="Filter by exercises"
              @change="loadResults"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">

          <b-button
              style="margin-top:2.5em"
              id="searchButton"
							variant="primary"
							@click="loadResults"
						>
							<feather-icon icon="SearchIcon" class="mr-25" />
							<span class="text-nowrap">Search</span>
						</b-button>
        </b-col>
      </b-row>

      <!-- Results Table Card -->
      <results-table :table-data="data.results" />
      <!--/ Results Table Card -->
    </b-card>
    
  </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import { BCard, BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import ResultsTable from "../components/ResultsTable.vue";

export default {
  data() {
    return {
      //selected: { title: 'Square' },
      // optionUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      // optionAdvices: [{ title: "Contact GP" }, { title: "Call 111" }],
      // optionExercises: [{ title: "Yes" }, { title: "No" }],
      urgencyLevels:[],
      advices:[],
      exercises:[],
      // optionUrgency: [],
      // optionAdvices: [],
      // optionExercise: [],
      selectedUrgencyLevel: [],
      selectedAdvice: [],
      selectedExercise: [],

      data: {
        results: [],
        // checkResults: [
        //   {
        //     id: 1,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //   },
        //   {
        //     id: 2,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "danger",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //   },
        //   {
        //     id: 3,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //   },
        //   {
        //     id: 4,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "warning",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //   },
        //   {
        //     id: 5,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "danger",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //   },
        //   {
        //     id: 6,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //   },
        //   {
        //     id: 7,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //   },
        // ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BButton,
    vSelect,
    ResultsTable,
  },
  methods: {
		async loadResults() {
			const res = await this.callApi("post", "/results/search", {
        ulId:this.selectedUrgencyLevel?this.selectedUrgencyLevel.id:0,
        advId:this.selectedAdvice?this.selectedAdvice.id:0,
        exeId:this.selectedExercise?this.selectedExercise.id:0
      });
			this.data.results = res.data.result;
      //console.log('HIER: '+JSON.stringify(this.data.results));
		},
    async loadExercises() {
      var url = '/exercises';
      const res = await this.callApi('get', url);
      this.exercises = res.data.result;
      //this.updateLoading();
    },
    async loadAdvices() {
      var url = '/advices';
      const res = await this.callApi('get', url);
      this.advices = res.data.result;
      //this.updateLoading();
    },
    async loadUrgencyLevels() {
      var url = '/urgencylevels';
      const res = await this.callApi('get', url);
      this.urgencyLevels = res.data.result;
      //this.updateLoading();
    },
  },
	mounted() {
    console.log('LOAD RESULTS1');
    
		this.loadResults();
    this.loadExercises();
    this.loadAdvices();
    this.loadUrgencyLevels();
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>